import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';

type TemplateType = "REGION" | "TERRITOIRE" | "DISTRIBUTEURS"
@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private readonly basePath: string = 'dashbord'
  readonly defaultBody = {
    "user": "adbak",
    "isSimpleLoading": false,
    "data": {
      "template": ""
    }
  }
  constructor(
    private restClient: RestClientService,
    private user: UserService
  ) { }

  getTemplateFile(templateType: "REGION" | "TERRITOIRE" | "DISTRIBUTEURS" | "LOCALITE"): Observable<any> {
    const bodyDatas = { ...this.defaultBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.data.template = templateType
    let httpOptions = { responseType: 'arraybuffer', observe: 'response' }
    return this.restClient.post(`${this.basePath}/template`, bodyDatas, httpOptions)
  }


}
